import './ForgetPassword.css'

import Heart from "../../App/Heart/Heart";
import ResetPasswordSend from "../ResetPasswordSend/ResetPasswordSend";
import {FormForgetPassword} from "./FormForgetPassword";

class ForgetPassword extends Heart {
    default = ''
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            alerts: ''
        }
    }
    render() {
        this.inputs = []
        return (
            <div className="MotdepasseOublier">
                <p className={"indication"}>Pour réinitialiser votre mot de passe, <br/>veuillez renseigner votre adresse mail :</p>
                <FormForgetPassword
                    refSubmit={this.refSubmit}
                    setAlert={(message, variant, dismissible) => this.setAlert(message, variant, dismissible)}
                    handleSubmit={(e) => this.handleSubmit(e)}
                    refForm={this}
                    alerts={this.state.alerts}
                />
                <p className={"retour"}>
                    <a
                        href={"/utilisateur/connexion"}
                        onClick={(e) => {
                            e.preventDefault()
                            /**
                             * TODO - METHODE A SUPPRIMER | VOIR POUR METTRE EN PLACE LE ROUTEUR SUR L'ENSEMBLE DU SITE
                             * **/
                            // this.handleNextComponent(<Connection Accueil={this.props.Accueil} App={this.props.App} />)
                            window.location.replace(window.location.origin);
                        }}
                        className={"text-black"}>
                        Retour
                    </a>
                </p>
            </div>
        );
    }
    handleSubmit(e) {

        e.preventDefault()

        /** Déclaration des variables */
        let email = document.getElementById('mail')

        this.props.App.Utilisateur.ForgetPassword(email.value).then(sortie => {
            this.props.Accueil.switchContainer(
                <ResetPasswordSend message={sortie.message} Accueil={this.props.Accueil} App={this.props.App} />
            )
        }, sortie => {
            this.setAlert(sortie.message)
        })
    }
    handleFormChange() {
        this.handleFormChangeAll()
    }
}

export default ForgetPassword