import PropTypes from "prop-types";
import axios from "axios";
import {decodeToken, isExpired} from "react-jwt";
import DefaultProfilPicture from '../../assets/image/Avatar.svg'

class Utilisateur {
    id: 0
    idCatPro: 0
    idDocumentPhotoProfil: 0
    info: 0
    codeAbonneur: ''
    codeStatut: ''
    cp: ''
    dateInscription: null
    dtRgpd: null
    email: ''
    nom: ''
    photoProfil: ''
    photoProfil64: ''
    prenom: ''
    telephone: ''
    notifGeneral: false
    notifEchange: false
    newsletter: []
    constructor() {

        /** Axios - Configuration par défaut */
        axios.defaults.baseURL                                                  = process.env.REACT_APP_API_DOMAIN;
        axios.defaults.headers.common['APIVersion']                             = process.env.REACT_APP_API_VERSION;
        axios.defaults.headers.common['AuthorizationApp']                       = process.env.REACT_APP_TOKEN_APP;
        axios.defaults.headers.common['Authorization']                          = window.localStorage.getItem('tokenUsr');
        axios.defaults.headers.common['Access-Control-Allow-Origin']            = '*';
        axios.defaults.headers.common['Access-Control-Allow-Headers']           = '*';
        axios.defaults.headers.common['Access-Control-Allow-Methods']           = '*';
        axios.defaults.headers.common['Content-Type']                           = 'application/json';
        axios.defaults.validateStatus = function (status) {
            return status < 500; // la requête résout tant que le code de sa réponse est// inférieur à 500
        }

        let u = window.localStorage.getItem('u')
        if(u) {
            u = JSON.parse(u)
            this.id = u.id
            this.idCatPro = u.idCatPro
            this.idDocumentPhotoProfil = u.idDocumentPhotoProfil
            this.info = u.info
            this.codeAbonneur = u.codeAbonneur
            this.codeStatut = u.codeStatut
            this.cp = u.cp
            this.dateInscription = u.dateInscription
            this.dtRgpd = u.dtRgpd
            this.email = u.email
            this.nom = u.nom
            this.photoProfil = u.photoProfil === '' ? DefaultProfilPicture : u.photoProfil
            this.prenom = u.prenom
            this.telephone = u.telephone
            this.notifGeneral = u.notifGeneral
            this.notifEchange = u.notifEchange
        }
    }
    Assistance(Sujet, Objet, Corps) {
        return new Promise((resolve, reject) => {
            axios.post('utilisateur/profil/assistance', {
                psSujet: Sujet,
                psObjet: Objet,
                psCorps: Corps
            }).then(data => {
                this.setTokenUsrExp(data.data.psTokenUsrExp)
                if(data.data.resultat) {
                    resolve(data.data)
                }
                reject(data.data)
            })
        });
    }
    setTokenUsrExp(date) {
        if(date) {
            localStorage.setItem('tokenUsrExp', date)
        }
    }
    Connexion(mail, password) {
        return new Promise((resolve, reject) => {
            axios.post('/utilisateur/connexion',{
                psEmail: mail,
                psMotDePasse: password,
                pnAvecAbonnements: 0
            }).then((Response) => {
                let Data = Response.data
                if(Data) {
                    if(Data.resultat) {
                        const myDecodedToken = decodeToken(Data.psTokenJwt);

                        if(!isExpired(Data.psTokenJwt)) {

                            let User = myDecodedToken.data;

                            this.id = User.id
                            this.codeAbonneur = User.codeAbonneur
                            this.codeStatut = User.codeStatut
                            this.email = User.email
                            this.nom = User.nom
                            this.photoProfil = User.psPhotoProfil ?? DefaultProfilPicture
                            this.prenom = User.prenom
                            this.telephone = User.telephone

                            localStorage.setItem('tokenUsr', myDecodedToken.psTokenUsr)
                            axios.defaults.headers.common['Authorization'] = myDecodedToken.psTokenUsr;
                            this.setTokenUsrExp(myDecodedToken.data.psTokenUsrExp)

                            resolve(Data)
                        }
                    }
                }
                reject(Data)
            });
        })
    }
    Charger() {
        return new Promise((resolve, reject) => {
            axios.post('utilisateur/profil/charger', {})
                .then((data) => {
                    let User = data.data

                    this.setTokenUsrExp(data.data.tokenUsrExp)
                    if(User.resultat) {

                        this.id = User.pnId
                        this.idCatPro = User.pnIdCatProf
                        this.idDocumentPhotoProfil = User.pnIdDocumentPhotoProfil
                        this.info = User.pnInfo
                        this.codeAbonneur = User.psCodeAbonneur
                        this.codeStatut = User.psCodeStatut
                        this.cp = User.psCp
                        this.dateInscription = User.psDateInscription
                        this.dtRgpd = User.psDtRgpd
                        this.email = User.psEmail
                        this.nom = User.psNom
                        this.photoProfil =  User.psPhotoProfil !== "" ? User.psPhotoProfil : DefaultProfilPicture
                        this.prenom = User.psPrenom
                        this.telephone = User.psTelephone
                        this.notifGeneral = User.pbNotifGeneral
                        this.notifEchange = User.pbNotifEchange

                        localStorage.setItem('u', JSON.stringify(this))

                        resolve(User)
                    }
                    reject(User)
                })
        });

    }
    ForgetPassword(email) {

        /** Déclaration des variables */
        let currentDomain = window.location.protocol+'//'+window.location.hostname
        if(window.location.port !== "")
            currentDomain += ':'+window.location.port

        /** Retour */
        return new Promise((resolve, reject) => {
            /** Demande de réinitialisation de mot de passe */
            axios.post('/utilisateur/motdepasse/reinitialiser', {
                psEmail: email,
                psUrlRedirection: currentDomain
            }).then(data => {
                if(data.data.resultat) {
                    resolve(data.data)
                }
                reject(data.data)
            })
        })
    }
    Modifier(object) {

        /** Préparation de l'envoie */
        let entree = {};
        if(object.nom) entree.psNom = object.nom
        if(object.prenom) entree.psPrenom = object.prenom
        if(object.cp || object.cp === '') entree.psCp = object.cp
        if(object.email) entree.psEmail = object.email
        if(object.telephone || object.telephone === '') entree.psTelephone = object.telephone
        if(object.idCatPro) entree.pnIdCatProf = object.idCatPro
        if(object.dtRgpd) entree.psDtRgpd = object.dtRgpd
        if(object.photoProfil) entree.pFichierPhoto = object.photoProfil
        if(object.photoProfilNom) entree.psNomFichier = object.photoProfilNom

        return new Promise((resolve, reject) => {

            /** Modification de l'utilisateur */
            axios.post('utilisateur/profil/modifier', entree).then((sortie) => {

                /** Récupération de la sortie */
                let Res = sortie.data

                if(Res.resultat) {
                    /** Modification de la date d'expiration */
                    this.setTokenUsrExp(Res.tokenUsrExp)

                    /** Traitement de success */
                    resolve(Res)
                }
                reject(Res)
            })
        })
    }
    UpdatePassword(currentPassword, newPassword) {
        return new Promise((resolve, reject) => {
            axios.post('/utilisateur/profil/modifier/motdepasse', {
                psMotdepasse: currentPassword,
                psNouveauMotDePasse: newPassword
            }).then((data) => {
                this.setTokenUsrExp(data.data.tokenUsrExp)
                if(data.data.resultat) {
                    resolve(data.data)
                }
                reject(data.data)
            })
        })
    }
    CrudProfessions() {
        return new Promise((resolve, reject) => {
            axios.post('/crud/professions')
            .then((data) => {
                let Liste = data.data
                this.setTokenUsrExp(Liste.tokenUsrExp)
                if(Liste.resultat) {
                    resolve(Liste)
                }
                reject(Liste)
            })
        })
    }

    DemandeAssistanceLister() {
        return new Promise((resolve, reject) => {
            axios.post('/gecko/chargeliste', {
                psCodeTypeObj: "assistanceedl"
            }).then((data) => {
                let Liste = data.data
                this.setTokenUsrExp(Liste.tokenUsrExp)
                if(Liste.resultat) {
                    resolve(Liste)
                }
                reject(Liste)
            })
        })
    }

    NewsletterLister() {
        return new Promise((resolve, reject) => {
            axios.post('/newsletter/lister', {
                pnIdUser: this.id
            }).then((data) => {
                let Liste = data.data
                this.setTokenUsrExp(Liste.tokenUsrExp)
                if(Liste.resultat) {
                     resolve(Liste)
                }
                reject(Liste)
            })
        })
    }

    PasswordRegex() {
        return new Promise((resolve, reject) => {
            axios.post('/motdepasse/prerequis', {
                pnIdUser: this.id
            }).then((data) => {
                let Liste = data.data
                this.setTokenUsrExp(Liste.tokenUsrExp)
                if(Liste.resultat) {
                    resolve(Liste)
                }
                reject(Liste)
            })
        })
    }

    // NewsletterInscription(nIdNewsletter, bInscrit) {
    NewsletterInscription(tIdsNewsletter) {
        tIdsNewsletter.forEach((l) => {
            tIdsNewsletter.pnIdUsr = this.id
        })

        return new Promise((resolve, reject) => {
            axios.post('/newsletter/inscription', {
                pnIdUsr: this.id,
                ptIdsNewsletter: tIdsNewsletter
            }).then((data) => {
                let Sortie = data.data
                this.setTokenUsrExp(Sortie.tokenUsrExp)
                if(Sortie.resultat) {
                    resolve(Sortie)
                }
                reject(Sortie)
            })
        });
    }
    ConsentementRGPD() {
        return new Promise((resolve, reject) => {
            axios.post('/utilisateur/profil/consentement/rgpd')
            .then((data) => {
                this.setTokenUsrExp(data.data.tokenUsrExp)
                if(data.data.resultat) {
                    resolve(data.data)
                }
                reject(data.data)
            })
        })
    }
    NotificationMail(NotifGeneral, NotifEchange) {
        return new Promise((resolve, reject) => {
            axios.post('/utilisateur/profil/notification/mail', {
                pbNotifGeneral: NotifGeneral,
                pbNotifEchange: NotifEchange
            }).then(data => {
                this.setTokenUsrExp(data.data.tokenUsrExp)
                if(data.data.resultat) {
                    resolve(data.data)
                }
                reject(data.data)
            })
        })
    }
    Supprimer() {
        return new Promise((resolve, reject) => {
            axios.post('/utilisateur/profil/supprimer', {}).then(data => {
                this.setTokenUsrExp(data.data.tokenUsrExp)
                if(data.data.resultat) {
                    resolve(data.data)
                } else {
                    reject(data.data)
                }
            })
        })
    }
    UpdateObject(u) {

        /** Modification de l'utilisateur en fonction des paramètres modifiés  */
        if(u.id) this.id = u.id
        if(u.idCatPro) this.idCatPro = u.idCatPro
        if(u.idDocumentPhotoProfil) this.idDocumentPhotoProfil = u.idDocumentPhotoProfil
        if(u.info) this.info = u.info
        if(u.codeAbonneur) this.codeAbonneur = u.codeAbonneur
        if(u.codeStatut) this.codeStatut = u.codeStatut
        if(u.cp || u.cp === '') this.cp = u.cp
        if(u.dateInscription) this.dateInscription = u.dateInscription
        if(u.dtRgpd) this.dtRgpd = u.dtRgpd
        if(u.email) this.email = u.email
        if(u.nom) this.nom = u.nom
        if(u.photoProfil) this.photoProfil =  u.photoProfil
        if(u.prenom) this.prenom = u.prenom
        if(u.telephone || u.telephone === '') this.telephone = u.telephone
        if(u.notifGeneral !== undefined) this.notifGeneral = u.notifGeneral
        if(u.notifEchange !== undefined) this.notifEchange = u.notifEchange

        /** Sauvegarde en localSession */
        window.localStorage.setItem('u', JSON.stringify(this))
    }
    Registrer(u) {
        return new Promise((resolve, reject) => {
            axios.post('/utilisateur/creation', u).then(data => {
                this.setTokenUsrExp(data.data.tokenUsrExp)
                if(data.data.resultat) {
                    resolve(data.data)
                }
                reject(data.data)
            })
        })
    }
}

Utilisateur.propTypes = {
    id: PropTypes.number,
    surname: PropTypes.string,
    firstname: PropTypes.string,
    mail: PropTypes.string,
    telephone: PropTypes.string
}
Utilisateur.defaultProps = {
    id: 0,
    surname: '',
    firstname: '',
    mail: '',
    telephone: ''
}

export default Utilisateur