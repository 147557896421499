import './Register.css'
import MyInput from "../../App/MyInput/MyInput";
import Heart from "../../App/Heart/Heart";
import Connection from "../Connection/Connection";
import {Regex} from "../../App/Regex";
import {FormRegister} from "./FormRegister";
import {FormForgetPassword} from "../ForgetPassword/FormForgetPassword";

class Register extends Heart {
    constructor(props) {
        super(props);
        this.state = {
            alerts: ''
        }
    }
    render() {
        this.inputs = []
        return (
            <div className="Register">
                <FormRegister
                    refSubmit={this.refSubmit}
                    setAlert={(message, variant, dismissible) => this.setAlert(message, variant, dismissible)}
                    handleSubmit={(e) => this.handleSubmit(e)}
                    refForm={this}
                    alerts={this.state.alerts}
                />
                <p className={"text-center"} style={{fontWeight: 400}}>
                    <span>Vous avez déjà un compte ? </span>
                    <a
                        href={"/utilisateur/connexion"}
                        className={"text-primary"}
                        style={{fontWeight: 600}}
                        onClick={(event) => {
                          event.preventDefault()
                          this.handleNextComponent(<Connection Accueil={this.props.Accueil} App={this.props.App} />)
                        }}
                    >Me connecter</a>
                </p>
            </div>
        );
    }
    handleSubmit(event) {

        /** Annule l'évènement navigateur */
        event.preventDefault();

        /** Déclaration des events */
        let Champ = {
            psNom: document.getElementById("name").value,
            psPrenom: document.getElementById("firstName").value,
            psEmail: document.getElementById("mail").value,
            psMotDePasse: document.getElementById("password").value
        }
        let submit = document.getElementById("RegisterAccount")
        submit.classList.add('disabled')
        submit.setAttribute('disabled', true)

        this.props.App.Utilisateur.Registrer(Champ)
            .then(() => {
                this.setAlert(<>Votre compte unique de l’école des loisirs est maintenant créé. <br />Il vous permettra de vous connecter à tous les sites du groupe école des loisirs.</>, 'success')
                setTimeout(() => {
                    this.handleNextComponent(<Connection Accueil={this.props.Accueil} App={this.props.App} />)
                }, 4000);
            }, sortie => {
                this.setAlert(sortie.message)
                submit.classList.remove('disabled')
                submit.removeAttribute('disabled')
            })
    }
    handleFormChange() {
        this.handleFormChangeAll()
    }
}

export default Register