const Regex: {
    password: { exp: RegExp, message: string },
    ville: { exp: RegExp, message: string },
    mail: { exp: RegExp, message: string },
    tel: { exp: RegExp, message: string },
    cp: { exp: RegExp, message: string }
} = {
    mail: {
        exp: /^(([a-zA-Z\d]+)([-_.][a-zA-Z\d])*)+@([a-zA-Z\d]?[-_.]?[a-zA-Z\d]){2,253}[.]([a-zA-Z]{2,})$/,
        message: 'Veuillez utiliser une adresse mail valide'
    },
    tel: {
        exp: /^\d*$/,
        message: 'Veuillez utiliser un telephone valide'
    },
    password: {
        exp: /^(?=.*?[+=%#/*?)(!,.&;@$^_~-])(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).{8,30}$/,
        message: 'Veuillez utiliser un mot de passe valide'
    },
    cp: {
        exp: /^[a-zA-Z\d\-\s]+$/,
        message: 'Veuillez utiliser un code postal valide'
    },
    ville: {
        exp: /^[a-zA-ZÀ-ÿ\-\s]+$/,
        message: 'Veuillez utiliser une ville valide'
    }
}

export {Regex}