import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      {process.env.REACT_APP_CAPTCHA_ACTIF === "true" ? <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_GOOGLE_SITE_KEY_CAPTCHA_V3}
          language="fr"
          scriptProps={{
              async: false,
              defer: false,
              appendTo: 'head', // ou 'body'
              nonce: undefined, // facultatif
          }}
          container={{
              element: "blockCaptchaV3",
              parameters: {
                  badge: '[inline|bottomright|bottomleft]', // facultatif
                  theme: 'dark', // facultatif
              }
          }}
      >
        <App />
      </GoogleReCaptchaProvider> : <App />}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
