import React from "react";
import MyAlert from "../MyAlert/MyAlert";

class Heart extends React.Component {
    inputs: []
    refForm = React.createRef()
    refSubmit = React.createRef()
    handleNextComponent(component): void {
        this.props.Accueil.switchContainer(component)
    }
    setAlert(message: { message: string }, variant:{ variant: string } = 'primary', dismissible: { dismissible: string } = true): void {
        this.setState({
            alerts: <MyAlert
                dismissible={dismissible}
                variant={variant}
                onClose={() => this.setState({ alerts: '' })}
                message={message} />
        })
    }
    addInput(element) {
        if(this.inputs) {
            let finding = false
            this.inputs.forEach(input => {
                if(input.id === element.id) {
                    finding = true;
                }
            })
            if(!finding) this.inputs.push(element)
        }
    }
    handleFormChangeEdited() {
        /** Nombre de champ valid */
        let nbValid = 0
        let nbEdited = 0

        /** Pour tous les champs du formulaire */
        this.inputs.forEach((input) => {
            if(input.getAttribute('data-edited') === 'true') {
                nbEdited++
                if (input.getAttribute('data-valid') === 'true') {
                    nbValid++
                }
            }
        })
        if(nbValid === nbEdited) {
            /** Le formulaire est valid */
            this.refSubmit.current.classList.remove('disabled')
            this.refSubmit.current.removeAttribute('disabled')
        } else {
            /** Le formulaire est in-valid */
            this.refSubmit.current.classList.add('disabled')
            this.refSubmit.current.setAttribute('disabled', true)
        }
    }
    handleFormChangeAll() {

        /** Nombre de champ valid */
        let nbValid = 0

        /** Pour tout les champs du formulaires */
        this.inputs.forEach((input) => {
            if (input.getAttribute('data-valid') === 'true') nbValid++
        })

        /** Gestion du bouton submit du formulaire */
        if(nbValid === this.inputs.length) {

            /** Le formulaire est valid */
            this.refSubmit.current.classList.remove('disabled')
            this.refSubmit.current.removeAttribute('disabled')
        } else {

            /** Le formulaire est in-valid */
            this.refSubmit.current.classList.add('disabled')
            this.refSubmit.current.setAttribute('disabled', true)
        }
    }
    ResetForm() {

        this.inputs.forEach((input) => {
            input.edited = false
            input.current.current.setAttribute('data-edited', false)
            input.current.current.classList.remove('is-valid')
        })

        this.refSubmit.current.classList.add('disabled')
        this.refSubmit.current.setAttribute('disabled', true)
    }
}

export default Heart