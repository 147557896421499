
import './App.css';
import Home from "../Home/Home";
import Dashboard from "../Dashboard/Dashboard";
import Heart from "./Heart/Heart";
import Utilisateur from './Utilisateur'
import moment from "moment";
import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ForgetPassword from "../Home/ForgetPassword/ForgetPassword";
import {Form} from "react-bootstrap";

class App extends Heart {
    Utilisateur = new Utilisateur()
    idInterval
    constructor(props) {
        super(props);
        this.state = {
            isLogging: this.isLogging()
        }
        console.log(process.env.REACT_APP_API_VERSION)
    }
    loopLogging() {
        this.idInterval = setInterval(() => {
            if(!this.isLogging()) {
                this.setLogging(false)
                clearInterval(this.idInterval)
            }
        }, 15000)
    }
    setLogging(pLog) {
        if (pLog === false) {
            window.localStorage.removeItem('u')
            window.localStorage.removeItem('tokenUsr')
            window.localStorage.removeItem('tokenUsrExp')
        }

        this.setState({ isLogging: pLog })
    }
    isLogging() {
        let token = window.localStorage.getItem('tokenUsr')
        let date = window.localStorage.getItem('tokenUsrExp')

        return token && moment(date) > moment()
    }
    render() {
        return (
            // <div className={"App"} id={"App"}>
            //     {this.state.isLogging ? <Dashboard App={this}/> : <Home App={this} path={(this.state.hasOwnProperty("path") ? this.state.path : null)}/>}
            // </div>
            <div className={"App"} id={"App"}>
                <BrowserRouter >
                    <Routes>
                        {this.state.isLogging ? <Route path="/*" element={<Dashboard App={this}/>} /> : <Route path="/*" element={<Home App={this} />} />}
                        {/*<Route path="/*" element={<Dashboard App={this}/>}/>*/}
                        <Route path="/motdepasse/oublier" element={<Home App={this} component={<ForgetPassword App={this} />} />}/>
                    </Routes>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;