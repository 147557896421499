import './Newsletter.css'
import Heart from "../../../App/Heart/Heart";
import NewsletterItem from "./NewsletterItem";
import {json} from "react-router-dom";

class Newsletter extends Heart {
    constructor(props) {
        super(props);

        this.state = {
            newsletter: []
        }
    }
    componentDidMount() {
        this.props.App.Utilisateur.NewsletterLister()
            .then((data) => {
                this.setState({newsletter: data.datas})
            });
    }
    handleOnChange(e) {
        let current = e.currentTarget
        let submit = document.getElementById('UpdateNewsletter')

        submit.classList.add('disabled')
        submit.setAttribute('disabled', true)

        Array.from(current.getElementsByTagName('input')).forEach((l) => {
            if(l.getAttribute('data-edited') === 'true') {
                submit.classList.remove('disabled')
                submit.removeAttribute('disabled')
            }
        })
    }
    handleSubmit(e) {

        e.preventDefault()

        let submit = document.getElementById('UpdateNewsletter')
        let current = e.currentTarget
        let ptIdsNewsletter = [];

            submit.classList.add('disabled')
        submit.setAttribute('disabled', true)

        Array.from(current.getElementsByTagName('input')).forEach((l) => {
            if(l.getAttribute('data-edited') === 'true') {
                let jsonNewsletter = {};
                jsonNewsletter.pnIdNewsLetter = l.getAttribute('data-id')
                jsonNewsletter.pbInscrit = l.checked
                ptIdsNewsletter.push(jsonNewsletter)
                // this.props.App.Utilisateur.NewsletterInscription(l.getAttribute('data-id'), l.checked)
            }
        })
        this.props.App.Utilisateur.NewsletterInscription(ptIdsNewsletter)
            .then(() => {
                this.setAlert("Modification réussie", 'success')
            }, () => {
                // l.click()
                this.setAlert("Modification échoué")
        });
    }
    render() {
        let NewsletterItems = []
        this.state.newsletter.forEach((ligne) => {
            if(ligne.nActive === 1)
                NewsletterItems.push(<NewsletterItem key={ligne.nId} id={'Newsletter'+ligne.nId} dataId={ligne.nId} title={ligne.sLibelle} description={ligne.sDescription} checked={!!ligne.nInscrit} />)
        })
        return (
            <form className="Newsletter"
                  onSubmit={(e) => this.handleSubmit(e) }
                  onChange={this.handleOnChange}
            >
                <p>Pour des raisons techniques, il est possible que vous receviez d’autres mails dans un délai maximal de 48h une fois désabonné(e) de la newsletter correspondante.</p>
                {NewsletterItems}
                <div className="text-center">
                    {this.state.alerts}
                    <button className={"btn btn-primary disabled buttonPadding"} disabled={true} type={"submit"} id={"UpdateNewsletter"}>Enregistrer les modifications</button>
                </div>
            </form>
        )
    }
}

export default Newsletter