import './MySelect.css'

import React from "react";
import PropTypes from "prop-types";
import ChevronDown from "../../../assets/icon/ChevronDown.svg"

class MySelect extends React.Component {
    defaultValue
    constructor(props) {
        super(props);

        this.edited = false
        this.state = {
            validData: '',
            induceFill: this.props.induceFill ?? ''
        }
        this.current = React.createRef()
        this.defaultValue = props.value
        this.refForm = this.props.refForm
    }

    render() {
        let induceFillClass = (this.state.induceFill && ['', '0', 0].indexOf(this.props.value) !== -1) ? 'InduceFill' : ''
        let options = [];
        options.push(<option value={'0'} key={"0"}>{this.props.placeholder}</option>)
        for(let line of this.props.data) {
            options.push(<option value={line.key} key={line.key}>{line.label}</option>)
        }
        let value = {}
        if(this.props.value) value['value'] = this.props.value
        return (
            <div className={"SelectContainer " + this.props.className + ' ' + induceFillClass}>
                <div className="label">
                    <label htmlFor={this.props.id}>{this.props.label}</label>
                    <div className="chevron">
                        <img src={ChevronDown} alt="" />
                    </div>
                    <select
                        ref={(e) => {
                            if(e !== null && this.refForm)
                                this.refForm.addInput(e)
                        }}
                        name={this.props.name}
                        id={this.props.id}
                        placeholder={this.props.placeholder}
                        required={this.props.required}
                        {...value}
                        onChange={(e) => {
                            this.handleOnChange(e)
                            if(this.props.onChange)
                                this.props.onChange(e)
                        }} >
                        {options}
                    </select>
                    <span className={"badgeInduceFill"}><div></div></span>
                    {this.state.msgError}
                </div>
                {(this.props.description !== '') ? <span className={"description"}>{this.props.description}</span> : ''}
            </div>
        )
    }
    handleOnChange(e) {
        /** Déclaration des variables */
        let current = e.currentTarget
        if(current) {

            /** Mémorise la valeur en cours */
            this.value = current.value

            /** Identifie si le champs est modifié ou non */
            this.edited = this.defaultValue !== current.value
            current.setAttribute('data-edited', this.edited)
        }

        current.setAttribute('data-valid', this.Valid())

        /** Désactive l'insitation au remplissage */
        if(this.props.induceFill)
            this.setState({ induceFill: (['', '0', 0].indexOf(current.value) !== -1) })

        /** Controle la validité du formulaire */
        this.props.refForm.handleFormChange()
    }
    Valid() {
        /** Réinitialise la validité du champ */
        this.valid = true

        /** Test si le champ est obligatoire */
        this.valid = this.validRequired()

        /** Retour de la validité du champ */
        return this.valid
    }
    isEmpty() {
        return (['0', '', 0, undefined].indexOf(this.value) !== -1)
    }
    validRequired() {
        if(this.props.required) {
           return !this.isEmpty() // true = remplit
        }
        return true
    }
}
MySelect.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    description: PropTypes.object
}
MySelect.defaultProps = {
    description: null,
    placeholder: '',
    value: '',
    className: ''
}

export default MySelect