import './Dashboard.css';
import Heart from "../App/Heart/Heart";
import Logo from "../../logo.svg";
import Menu from "../../assets/icon/BarMenu.svg";
import MenuBadge from "../../assets/icon/BarMenuBadge.svg";
import Croix from "../../assets/icon/Cross.svg";
import MenuItem from "./Menu/MenuItem";
import Newsletter from "./Content/Newsletter/Newsletter";
import DefaultPictureProfil from "../../assets/image/DefaultPictureProfil.jpg";
import Profil from "./Content/Profil/Profil";
import UpdatePassword from "./Content/UpdatePassword/UpdatePassword";
import RGPD from "./Content/RGPD/RGPD";
import Help from "./Content/Help/Help";
import UserDelete from "./Content/UserDelete/UserDelete";
import NotificationMail from "./Content/NotificationMail/NotificationMail";
import {Regex} from "../App/Regex";
import moment from "moment";

class Dashboard extends Heart {
    constructor(props) {
        super(props);
        this.App = this.props.App
        this.state = {
            title: "Mes Coordonnées",
            body: <Profil App={this.props.App} Dashboard={this} />,
            photo: this.props.App.Utilisateur.photoProfil ?? DefaultPictureProfil,
            menu: false
        }
    }
    componentDidMount() {
        this.secuFillable()
        if(localStorage.hasOwnProperty("idMenu") && localStorage.idMenu !== "")
        {
            this.state.menu = true
            document.getElementById(localStorage.idMenu).click()
            if(document.getElementById(localStorage.idMenu).parentNode.classList.contains("SubMenus"))
            {
                /** Reset */
                let AllMain = document.getElementsByClassName('MainMenu')
                Array.from(AllMain).forEach(m => m.classList.remove('active'));

                document.getElementById(localStorage.idMenu).parentNode.classList.remove("hide");
                document.getElementById(localStorage.idMenu).parentNode.previousSibling.classList.add("active");
            }
        }
    }

    switchContent(Title, Component) {
        this.setState({
            title: Title,
            body: Component
        })
    }
    UpdatePhoto(photo) {
        this.setState({
            photo: photo
        })
    }
    Deconnection(e) {
        e.preventDefault()
        window.localStorage.removeItem('u')
        window.localStorage.removeItem('tokenUsr')
        window.localStorage.removeItem('tokenUsrExp')
        window.localStorage.removeItem("idMenu");
        this.props.App.setLogging(false)
    }
    DisplaySubmenu(e) {
        let Target = e.currentTarget
        let Menu = Target.parentNode
        let Submenu = Menu.lastChild

        let AllSub = document.getElementsByClassName('SubMenus')

        Array.from(AllSub).forEach(m => m.classList.add('hide'));

        if(Target.classList.contains('active')) {
            Submenu.classList.remove('hide')
        }
    }
    MainMenuActive(e) {
        /** Reset */
        let AllMain = document.getElementsByClassName('MainMenu')
        Array.from(AllMain).forEach(m => m.classList.remove('active'));

        /** Active */
        let MainMenu = e.currentTarget
        Array.from(MainMenu.parentNode.getElementsByClassName("Submenu"))
            .forEach(m => m.classList.remove('active'))
        MainMenu.classList.add('active')
    }
    SubMenuActive(SubMenu) {
        /** Reset */
        let AllSubMain = document.getElementsByClassName('Submenu')
        Array.from(AllSubMain).forEach(m => m.classList.remove('active'));

        /** Active */
        SubMenu.classList.add('active')
    }
    toggleMenu() {
        this.setState({ menu: !this.state.menu })
    }
    getAction() {
        let badge = false
        if(this.state.subtitleSecu !== "" || this.state.subtitleInfo !== "") {
            badge = true
        }
        let Action = <>
            <img src={badge ? MenuBadge : Menu } alt=""/>
            <span>Menu</span>
        </>
        if(this.state.menu) {
            // Open
            Action = <>
                <img src={Croix} alt=""/>
                <span>Fermer</span>
            </>
        }

        return Action
    }
    profilInducableFill() {
        /** Déclaration des variables */
        let missingInfo = true;

        // console.log('')
        // console.log('---------------------')
        // console.log('')

        /** Nom */
        if(this.props.App.Utilisateur.nom !== undefined) {
            missingInfo = this.props.App.Utilisateur.nom.trim() === ''
            // console.log('nom : '+this.props.App.Utilisateur.nom + " --> " + missingInfo)
        }

        /** Prenom */
        if(!missingInfo && this.props.App.Utilisateur.prenom !== undefined) {
            if(this.props.App.Utilisateur.prenom.trim() === '') {
                missingInfo = true
            }
            // console.log('prenom : '+this.props.App.Utilisateur.prenom + " --> " + missingInfo)
        }

        /** Code postal */
        if(!missingInfo && this.props.App.Utilisateur.cp !== undefined) {
            if(this.props.App.Utilisateur.cp.trim() !== '') {
                missingInfo = (this.props.App.Utilisateur.cp.match(Regex.cp.exp) === null)
            } else {
                missingInfo = true
            }
            // console.log('cp : '+this.props.App.Utilisateur.cp + " --> " + missingInfo+" - "+this.props.App.Utilisateur.cp.match(Regex.cp.exp))
        }

        /** Code postal */
        if(!missingInfo && this.props.App.Utilisateur.email !== undefined) {
            if(this.props.App.Utilisateur.email.trim() !== '') {
                missingInfo = (this.props.App.Utilisateur.email.match(Regex.mail.exp) === null)
            } else {
                missingInfo = true
            }
            // console.log('mail : '+this.props.App.Utilisateur.email+" --> "+missingInfo+" - "+this.props.App.Utilisateur.email.match(Regex.mail.exp))
        }

        /** Téléphone */
        if(!missingInfo && this.props.App.Utilisateur.telephone !== undefined) {
            if(this.props.App.Utilisateur.telephone.trim() !== '') {
                missingInfo = (this.props.App.Utilisateur.telephone.match(Regex.tel.exp) === null)
            } else {
                missingInfo = true
            }
            // console.log('telephone : '+this.props.App.Utilisateur.telephone + " --> " + missingInfo+" - "+this.props.App.Utilisateur.telephone.match(Regex.tel.exp))
        }

        /** Catégorie professionnelle */
        if(!missingInfo && this.props.App.Utilisateur.idCatPro !== undefined) {
            missingInfo = this.props.App.Utilisateur.idCatPro === 0
            // console.log('idCatPro : '+this.props.App.Utilisateur.idCatPro + " --> " + missingInfo)
        }

        this.InfoSubtitle(missingInfo)
    }
    secuFillable() {

        let Warning = new moment()
        Warning.add(3, 'years').subtract(3, 'months')

        let dt = new moment(this.props.App.Utilisateur.dtRgpd)
        this.SecuSubtitle(dt.isBefore(Warning))
    }
    InfoSubtitle(val) {
        this.setState({ subtitleInfo: (!val) ? "" : "Informations manquantes" })
    }
    SecuSubtitle(val) {
        this.setState({ subtitleSecu: (val) ? "" : "Action requise" })
    }
    render() {
        let Open = (this.state.menu) ? ' Open' : ''
        let Action = this.getAction()
        return (
            <div id="Dashboard">
                <div id={"Menu"} className={Open}>
                    <div className="Header">
                        <div className="Logo">
                            <img src={Logo} alt=""/>
                        </div>
                        <div className="Action" id="ToggleMenu" onClick={e => this.toggleMenu(e) }>{Action}</div>
                    </div>
                    <div className="Body">
                        <div className="Header">
                            <h1>Mon compte</h1>
                            <div className="ProfilPicture">
                                <img src={this.state.photo} alt=""/>
                            </div>
                        </div>
                        <div className="MenuContent">
                            <MenuItem
                                id={"menuProfil"}
                                icon={(this.state.subtitleInfo === "") ? <i className="edl-profil"></i> : <i className="edl-profil-badge"></i>}
                                title={"Mes Coordonnées"}
                                link={"/utilisateur/profil"}
                                refDash={this}
                                subtitle={this.state.subtitleInfo}
                                className={"active"}
                                onClick={(e) => {
                                    localStorage.idMenu = e.currentTarget.id
                                    this.MainMenuActive(e)
                                    this.DisplaySubmenu(e)
                                    this.toggleMenu()
                                    this.switchContent("Mes Coordonnées", <Profil App={this.props.App} Dashboard={this} />)
                                }} />
                            <MenuItem
                                id={"menuNewsletter"}
                                icon={<i className="edl-mail"></i>}
                                title={"Gérer mes Newsletters"}
                                //subtitle={this.state.subtitleNews}
                                link={"/utilisateur/newsletters"}
                                onClick={(e) => {
                                    localStorage.idMenu = e.currentTarget.id
                                    this.MainMenuActive(e)
                                    this.DisplaySubmenu(e)
                                    this.toggleMenu()
                                    this.switchContent("Gérer mes Newsletters", <Newsletter App={this.props.App} />)
                                }} />
                            <MenuItem
                                icon={(this.state.subtitleSecu === '') ? <i className="edl-security" style={{fontSize: '18px'}}></i> : <i className="edl-security-badge" style={{fontSize: '18px'}}></i>}
                                title={"Sécurité & Confidentialité"}
                                subtitle={this.state.subtitleSecu}
                                id={"menuSecurity"}
                                link={"utilisateur/security"}
                                onClick={(e) => {
                                    this.MainMenuActive(e)
                                    this.DisplaySubmenu(e)
                                }}
                                subMenus={[
                                    {
                                        id: 'UserUpdatePassword',
                                        label: '• Modifier mon mot de passe',
                                        link: 'utilisateur/password/update',
                                        onClick: (e) => {
                                            localStorage.idMenu = e.currentTarget.id
                                            this.SubMenuActive(e.currentTarget)
                                            this.toggleMenu()
                                            this.switchContent("Sécurité & Confidentialité", <UpdatePassword App={this.props.App} Dashboard={this} />)
                                        }
                                    },
                                    {
                                        id: 'UserRGPD',
                                        label: '• Gérer mon consentement RGPD',
                                        link: 'utilisateur/rgpd',
                                        onClick: (e) => {
                                            localStorage.idMenu = e.currentTarget.id
                                            this.SubMenuActive(e.currentTarget)
                                            this.toggleMenu()
                                            this.switchContent("Sécurité & Confidentialité", <RGPD App={this.props.App} />)
                                        }
                                    }
                                ]}
                            />
                            <MenuItem
                                icon={<i className="edl-interrogation" style={{fontSize: '18px'}}></i>}
                                title={"Assistance"}
                                id={"Help"}
                                link={"utilisateur/help"}
                                onClick={(e) => {
                                    localStorage.idMenu = e.currentTarget.id
                                    this.MainMenuActive(e)
                                    this.DisplaySubmenu(e)
                                    this.toggleMenu()
                                    this.switchContent("Assistance", <Help App={this.props.App} />)
                                }}
                            />
                            <MenuItem
                                icon={<i className="edl-settings" style={{fontSize: '18px'}}></i>}
                                id={"Settings"}
                                title={"Paramètres"}
                                link={"utilisateur/settings"}
                                onClick={(e) => {
                                    this.MainMenuActive(e)
                                    this.DisplaySubmenu(e)}
                                }
                                subMenus={[
                                    /*{
                                        id: 'NotificationsMail',
                                        label: '• Notifications par mail',
                                        link: 'notification/mail',
                                        onClick: (e) => {
                                            localStorage.idMenu = e.currentTarget.id
                                            this.SubMenuActive(e.currentTarget)
                                            this.toggleMenu()
                                            this.switchContent('Paramètres', <NotificationMail App={this.props.App} />)
                                        }
                                    },*/
                                    {
                                        id: 'DeleteProfil',
                                        label: '• Supprimer mon compte',
                                        link: 'utilisateur/delete',
                                        onClick: (e) => {
                                            localStorage.idMenu = e.currentTarget.id
                                            this.SubMenuActive(e.currentTarget)
                                            this.toggleMenu()
                                            this.switchContent('Paramètres', <UserDelete App={this.props.App} Dashboard={this}/>)
                                        }
                                    }
                                ]}
                            />
                        </div>
                        <div className={"Deconnexion"}>
                            <i className="edl-logout"></i>
                            <a href={"utilisateur/deconnexion"} onClick={(e) => this.Deconnection(e) }>Déconnexion</a>
                        </div>
                    </div>
                </div>
                <div className="Content">
                    <div className="Header">
                        <div className="Title"><h2>{this.state.title}</h2></div>
                        <div className="ProfilPicture"><img src={this.state.photo} alt=""/></div>
                    </div>
                    <div className="Body">{this.state.body}</div>
                </div>
            </div>
        )
    }
}

export default Dashboard