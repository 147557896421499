import Heart from "../../../App/Heart/Heart";
import MySwitch from "../../../App/MySwitch/MySwitch";

class NewsletterItem extends Heart {
    render() {
        return (
            <div className="NewsletterItem">
                <div className="Details">
                    <p className="Title">{this.props.title}</p>
                    <p className="Description">{this.props.description}</p>
                </div>
                <MySwitch checked={this.props.checked} id={this.props.id} dataId={this.props.dataId}/>
            </div>
        );
    }
}

export default NewsletterItem