import './Information.css'
import Info from '../../../assets/icon/Exclamation.svg'
import Heart from "../Heart/Heart";

class Information extends Heart {
    render() {
        return (
            <div className="Information">
                <div className="I-Header">
                    <img src={Info} alt="Exclamation"/>
                    <h4>{this.props.title}</h4>
                </div>
                <div className="details">
                    {this.props.description}
                </div>
            </div>
        )
    }
}

export default Information