import './RGPD.css'
import Heart from "../../../App/Heart/Heart";
import moment from "moment";

class RGPD extends Heart {
    constructor(props) {
        super(props);

        this.state = this.setRGPD(this.props.App.Utilisateur.dtRgpd)
    }
    setRGPD(DateRGPD) {
        let Date = new moment(DateRGPD)
        Date.add('3', 'y')

        this.props.App.Utilisateur.dtRgpd = DateRGPD

        return {
            Debut: (new moment(this.props.App.Utilisateur.dtRgpd)).format('DD/MM/YYYY'),
            Fin: Date.format('DD/MM/YYYY')
        }
    }
    handleSubmit(e) {

        e.preventDefault()

        let submit = document.getElementById('UpdateRGPD')
        submit.classList.add('disabled')
        submit.setAttribute('disabled', true)

        this.props.App.Utilisateur.ConsentementRGPD()
            .then(data => {
                this.setState(this.setRGPD(data.dtRgpd))
                this.setAlert(data.message, 'success')
                submit.classList.remove('disabled')
                submit.removeAttribute('disabled')
            }, data => {
                this.setAlert(data.message)
                submit.classList.remove('disabled')
                submit.removeAttribute('disabled')
            })
    }
    render() {
        /** Date */
        return (
            <div className={"RGPD"}>
                <div className="Subtitle">
                    <h3>Gérer mon consentement RGPD</h3>
                    <p>
                        Le respect de la vie privée est devenu un sujet particulièrement sensible – et ce, pour de bonnes raisons.
                        <br/>À l'école des loisirs, nous sommes conscients de l’importance de cet enjeu et le prenons très à cœur.
                        <br/> C’est la raison pour laquelle nous traitons vos informations avec la plus grande prudence.
                        <br/><br/> De temps à autre, nous souhaiterions vous tenir informé, par email, des nouveautés concernant nos livres et services, des événements que nous organisons autour de nos auteurs. Conformément aux objectifs de transparence du RGPD (Règlement Général sur la Protection des Données), nous enregistrons la date à laquelle vous avez donné votre accord pour recevoir de l'information de notre part. Cette date d'acceptation est présente ci-dessous.
                        <br/><br/>Tous les 3 ans nous demandons à nos utilisateurs s'ils souhaitent renouveler leur consentement pour 3 années supplémentaires. Vous avez la possibilité de renouveler votre consentement ci-dessous, sans attendre la fin de cette période. Ainsi nous serons heureux de continuer à vous envoyer nos newsletters pour les 3 prochaines années. Merci par avance pour votre fidélité à notre maison !
                        <br/><br/>Consulter notre <a href="https://www.ecoledesloisirs.fr/charte-utilisateurs-site-ecole-loisirs-application-kili-maj-20-octobre-2022" rel="noreferrer"  target={"_blank"}>politique de confidentialité</a>.
                    </p>
                </div>
                <div className={"RGPD-Container"}>
                    <div className={"RGPD-Dates"}>
                        <div className={"Debut"}>
                            <p>Vous avez consenti à notre politique de confidentialité le :</p>
                            <p className={"Date"} id={"dateRGPD"}>{this.state.Debut}</p>
                        </div>
                        <div className={"Fin"}>
                            <p>Ce consentement est valide jusqu’au :</p>
                            <p className={"Date"} id={"dateFinRGPD"}>{this.state.Fin}</p>
                        </div>
                    </div>
                    <form onSubmit={(e) => this.handleSubmit(e)} className="text-center">
                        <p className={"Description"}>En cliquant sur “Renouveler mon consentement”, vous renouvelez votre accord pour une période de 3 ans.</p>
                        {this.state.alerts}
                        <button className={"btn btn-primary buttonPadding"} type={"submit"} id={"UpdateRGPD"}>Renouveler mon consentement</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default RGPD