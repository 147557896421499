import './UserDelete.css'
import Heart from "../../../App/Heart/Heart";
import Information from "../../../App/Information/Information";
import {Button, Form, Modal} from "react-bootstrap";

class UserDelete extends Heart {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            alerts: ''
        }
    }
    handleSubmit(e) {
        e.preventDefault()

        let submit = document.getElementById('validateForm')
        submit.classList.add('disabled')
        submit.setAttribute('disabled', true)

        let Coche = document.getElementById("validationDelete")
        if(Coche.checked) {
            this.props.App.Utilisateur.Supprimer().then(() => {
                this.setState({ show: true });
            }, data => {
                this.setAlert(data.message)
            })
        }
    }
    render() {
        return (
            <div className={"UserDelete"}>
                <div className="Subtitle">
                    <h3>Supprimer mon compte</h3>
                    <p>Nous vous invitons à effectuer une sauvegarde des documents avant de supprimer votre compte.</p>
                    <p className={"fw-bolder"}>Une fois celui-ci supprimé, vous ne pourrez plus accéder aux données qui y sont associées.</p>
                </div>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    <Information
                        title={"Un problème ? Une remarque ?"}
                        description={<p>Si vous rencontrez un problème avec votre compte ou avec l’un des sites du groupe l’école <br/>des loisirs, vous pouvez contacter notre <a href={"/help"} onClick={(e) => {
                            e.preventDefault()
                            let MenuHelp = document.getElementById("Help")
                            if(MenuHelp) MenuHelp.click()
                        }}>assistance</a> à tout moment afin d’obtenir de l’aide.</p>} />
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" id={"validationDelete"} label="Je comprends qu’en supprimant mon compte, je perds l’accès à l’ensemble des données associées à celui-ci et que cette action est irréversible." onChange={(e) => {
                            let button = document.getElementById("validateForm")
                            button.disabled = !e.currentTarget.checked
                            if(e.currentTarget.checked) {
                                button.classList.remove('disabled')
                                button.removeAttribute('disabled')
                            } else {
                                button.classList.add('disabled')
                                button.setAttribute('disabled', true)
                            }
                        }} />
                    </Form.Group>
                    <Modal id={"ModalDelete"} show={this.state.show} onHide={this.hide}>
                        <Modal.Header closeButton>
                            <Modal.Title>Compte supprimé</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Votre compte vient d'être supprimé.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => this.props.App.setLogging(false)}>
                                Retour à l'accueil
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {this.state.alerts}
                    <Button type={"submit"} variant={"primary"} id={"validateForm"} className={"disabled buttonPadding"} disabled={true}>Supprimer mon compte</Button>
                </form>
            </div>
        )
    }
}

export default UserDelete