import MyInput from "../../App/MyInput/MyInput";
import {Regex} from "../../App/Regex";
import React from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import axios from "axios";

export const FormForgetPassword = ({ refSubmit, refForm, handleSubmit, alerts, setAlert }) => {

    const {executeRecaptcha} = useGoogleReCaptcha();

    return <form onSubmit={async (e) => {

        e.preventDefault()

        if(process.env.REACT_APP_CAPTCHA_ACTIF === "true")
        {
            const token = await executeRecaptcha('login')

            /** Vérifiez le score (par exemple, si le score est supérieur à 0.5, considérez-le comme valide) */
            if (token) {
                axios.post("/google/token/recaptcha", {
                    token: token
                }, {
                    baseURL: process.env.REACT_APP_DOMAIN_BACK
                }).then((Res) => {
                    const {success, score} = Res.data;
                    if (success && score > 0.5) {
                        handleSubmit(e)
                    } else {
                        setAlert('Échec de la validation reCAPTCHA.', 'danger', true)
                    }
                })
            }
        } else {
            handleSubmit(e)
        }
    }}>
        <MyInput
            label="Adresse mail"
            placeholder="adresse@mail.com"
            autocomplete="username"
            refForm={refForm}
            id="mail"
            name="mail"
            type="text"
            required={true}
            regex={Regex.mail}
        />
        {alerts ?? ''}
        <button className="btn btn-primary disabled" disabled={true} type={"submit"} ref={refSubmit}>Envoyer
        </button>
    </form>
}