import Carousel1 from '../../../assets/image/1. EdL.jpg';
import Carousel2 from '../../../assets/image/2. EdM.jpg';
import Carousel3 from '../../../assets/image/3. EdLM.jpg';
import Carousel4 from '../../../assets/image/4. EdLM.jpg';
import Carousel5 from '../../../assets/image/5. RdS.jpg';
import './MyCarousel.css';
import {Carousel} from "react-bootstrap";

function MyCarousel() {
    return (
        <div className="MyCarousel">
            <Carousel interval={null}>
                <Carousel.Item>
                    <img
                        className="d-block h-100 align-items-center"
                        src={Carousel1}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block h-100 align-items-center"
                        src={Carousel2}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block h-100 align-items-center"
                        src={Carousel3}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block h-100 align-items-center"
                        src={Carousel4}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block h-100 align-items-center"
                        src={Carousel5}
                        alt="First slide"
                    />
                </Carousel.Item>
            </Carousel>
        </div>
    );
}
export default MyCarousel;