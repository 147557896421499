import './Profil.css'

import Heart from "../../../App/Heart/Heart";
import MyInput from "../../../App/MyInput/MyInput";
import MySelect from "../../../App/MySelect/MySelect";
import DefaultProfilPicture from '../../../../assets/image/DefaultPictureProfil.jpg'
import {Regex} from "../../../App/Regex";
import React from "react";

class Profil extends Heart {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            idCatPro: 0,
            idDocumentPhotoProfil: 0,
            info: 0,
            codeAbonneur: '',
            codeStatutpsCodeStatut: '',
            cp: '',
            dateInscription: null,
            dtRgpd: null,
            email: '',
            nom: '',
            photoProfil: '',
            photoProfil64: '',
            prenom: '',
            telephone: '',
            crud: [],
            alerts: '',
            msgUpload: ''
        }
    }
    componentDidMount() {
        this.props.App.Utilisateur.CrudProfessions()
            .then((data) => {
                this.setState({
                    crud: data.datas.map((line) => {
                        return { key: line.ID, label: line.CODE + ' - ' + line.LIBELLE}
                    })
                })
            });
        this.setState(this.props.App.Utilisateur)
        this.props.Dashboard.profilInducableFill()
    }
    onFileChange = event => {

        /** Reset le message de'Upload */
        this.setState({ msgUpload: '' })

        /** recupération du champ upload */
        let current = event.currentTarget;
        if(event.target.files[0]) {

            /** Récupération de la photo */
            let File = event.target.files[0]
            let reader = new FileReader();

            /** Octet vers MegaOctet et doit être inférieur à 2 Mo */
            if(File.size / 1000000 < 2) {

                /** Transformation en base64 */
                reader.readAsDataURL(File);
                reader.addEventListener("load", () => {
                    let tmp = reader.result.split(',')
                    this.setState({ photoProfil64: tmp[1] });
                })

                /** Récuperation des informations de la photo*/
                this.setState({
                    photoProfilNom: File.name,
                    photoProfil: URL.createObjectURL(File)
                })

                /** Passage en Edité */
                current.setAttribute('data-edited', true)
            } else {
                /** La photo doit être inferieur à 2 Mo */
                this.setState({ msgUpload: <div className="msg-alert">
                        Le fichier doit être inférieur à 2 Mo
                </div> })
            }
            this.handleFormChange();
        }
    }

    // On file upload (click the upload button)
    handleSubmit(e) {
        /** Anunule l'event navigateurs */
        e.preventDefault()

        /** Bouton submit */
        let submit = document.getElementById('UpdateAccount')
        submit.classList.add('disabled')
        submit.setAttribute('disabled', true)

        /** Retourne tous les inputs et les selects du formulaire submit */
        let data = {}
        let inputs = Array.from(e.currentTarget.getElementsByTagName('input'))
        inputs = inputs.concat(Array.from(e.currentTarget.getElementsByTagName('select')))

        /** Parcours des champs */
        for (let i = 0; i < inputs.length; i++) {

            /** Envoie les champs uniquement modifié */
            let edit = inputs[i].getAttribute("data-edited")
            if(edit === 'true') {
                data[inputs[i].name] = inputs[i].type === 'file' ? this.state.photoProfil64 : inputs[i].value
                if(inputs[i].type === 'file')
                    data.photoProfilNom = this.state.photoProfilNom
            }
        }

        /** Modification de l'utilisateur */
        this.props.App.Utilisateur.Modifier(data)
            .then((sortie) => {
                /** Récupération des informations */
                let updated = data;
                /** Mise à jour de l'objet utilisateur */
                if(sortie.resultat) {
                    if(sortie.psPhotoProfil) updated.photoProfil = sortie.psPhotoProfil;
                    this.props.App.Utilisateur.UpdateObject(updated)
                }
                this.setState(this.props.App.Utilisateur)

                /** Mise à jour de la photo d'en-tête */
                this.props.Dashboard.UpdatePhoto(sortie.resultat === true ? this.props.App.Utilisateur.photoProfil : DefaultProfilPicture)

                /** Alert */
                this.setAlert(sortie.message, 'success', true)

                this.props.Dashboard.profilInducableFill()

            }, sortie => {
                /** Alert */
                this.setAlert(sortie.message)
            })
    }
    render() {
        this.inputs = []
        return (
            <div className="Profil">
                <div className={"PhotoProfilBody"}>
                    <div className="photoProfil">
                        <img src={this.state.photoProfil} alt="profil"/>
                    </div>
                    {this.state.msgUpload}
                    <button type={"button"} onClick={() => {
                        let input = document.getElementById("photoProfil")
                        if(input)
                            input.click()
                    }}>Modifier ma photo</button>
                </div>
                <form className={"row was-validated"}
                      onSubmit={(e) => this.handleSubmit(e)}
                      ref={this.refForm}
                      //onChange={(e) => this.handleFormChange(e)}>
                >
                    <input type="file" id="photoProfil" name="photoProfil" accept={"image/jpeg, image/png, image/jpg"} className={"d-none"} onChange={this.onFileChange}/>
                    <MyInput
                        refForm={this}
                        label="Nom*"
                        placeholder="DUPONT"
                        autocomplete="family-name"
                        className={"col-sm-6"}
                        id="nom"
                        value={this.state.nom}
                        name="nom"
                        type="text"
                        required={true}
                        induceFill={true}
                        onChange={(e) => {
                            let val = e.currentTarget.value
                            this.setState({nom:val}) }
                        }
                    />
                    <MyInput
                        refForm={this}
                        label="Prénom*"
                        placeholder="Louise"
                        autocomplete="given-name"
                        className={"col-sm-6"}
                        id="prenom"
                        name="prenom"
                        value={this.state.prenom}
                        type="text"
                        required={true}
                        induceFill={true}
                        onChange={(e) => {
                            let val = e.currentTarget.value
                            this.setState({prenom:val}) }
                        }
                    />
                    <MyInput
                        refForm={this}
                        label="Adresse mail*"
                        placeholder="adresse@mail.com"
                        autocomplete="email"
                        className={"col-12"}
                        id="email"
                        value={this.state.email}
                        name="email"
                        type="text"
                        required={true}
                        regex={Regex.mail}
                        induceFill={true}
                        onChange={(e) => {
                            let val = e.currentTarget.value
                            this.setState({email:val}) }
                        }
                    />
                    <MyInput
                        refForm={this}
                        label={"Téléphone"}
                        type={"text"}
                        name={"telephone"}
                        className={"col-sm-6"}
                        id={"telephone"}
                        value={this.state.telephone}
                        placeholder={"0676543210"}
                        regex={Regex.tel}
                        induceFill={true}
                        onChange={(e) => {
                            let val = e.currentTarget.value
                            this.setState({telephone:val}) }
                        }
                    />
                    <MyInput
                        refForm={this}
                        label={"Code postal"}
                        type={"text"}
                        name={"cp"}
                        autocomplete="off"
                        className={"col-sm-6"}
                        id={"cp"}
                        placeholder={"75006"}
                        value={this.state.cp}
                        regex={Regex.cp}
                        induceFill={true}
                        onChange={(e) => {
                            let val = e.currentTarget.value
                            this.setState({cp:val}) }
                        }
                    />
                    <MySelect
                        refForm={this}
                        label={"Catégorie Socio-Professionnelle"}
                        type={"text"}
                        name={"idCatPro"}
                        className={"col-12"}
                        id={"idCatPro"}
                        placeholder={"Veuillez sélectionner une catégorie socio-professionnelle"}
                        value={this.state.idCatPro}
                        data={this.state.crud}
                        induceFill={true}
                        onChange={(e) => {
                            let val = e.currentTarget.value
                            this.setState({idCatPro:val}) }
                        }
                    />
                    <div className={"text-center"}>
                        {this.state.alerts}
                        <button type={"submit"} className={"btn btn-primary disabled buttonPadding"} id={"UpdateAccount"} ref={this.refSubmit}>Modifier mes informations</button>
                    </div>
                </form>
            </div>
        )
    }
    handleFormChange() {
        this.handleFormChangeEdited()
    }
}

export default Profil