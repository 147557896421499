import Heart from "../../App/Heart/Heart";
import Connection from "../Connection/Connection";

class ResetPasswordSend extends Heart {
    render() {
        return (
            <div className="ForgetPasswordSend">
                <div className={"my-4"}>
                    <p className={"text-primary"}>{this.props.message}</p>
                </div>
                <button className="btn btn-primary" onClick={() => {
                    /**
                     * TODO - METHODE A SUPPRIMER | VOIR POUR METTRE EN PLACE LE ROUTEUR SUR L'ENSEMBLE DU SITE
                     * **/
                    window.location.replace(window.location.origin);
                    // this.handleNextComponent(<Connection Accueil={this.props.Accueil} App={this.props.App} />)
                }}>Retour</button>
            </div>
        );
    }
}

export default ResetPasswordSend