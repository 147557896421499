import './MyTextaera.css'

import React, {Component} from "react";
import PropTypes from "prop-types";
import Show from "../MyInput/show.svg";
import Hide from "../MyInput/hide.svg";

class MyTextaera extends Component {
    defaultValue
    constructor(props) {
        super(props);

        this.edited = false;
        this.valid = true;
        this.refForm = this.props.refForm
        this.state = {
            type: this.props.type,
            imgToggle: this.imgShow,
            msgError: '',
            validData: '',
            induceFill: this.props.induceFill ?? ''
        }
        this.defaultValue = props.value
        this.value = this.props.value
        this.required = this.props.required
    }
    render() {
        return (
            <div className={"TextareaContainer " + this.props.className}>
                <div className="Textarea-Label">
                    <label htmlFor={this.props.id}>{this.props.label}</label>
                    <textarea
                        name={this.props.name}
                        id={this.props.id}
                        rows={this.props.row}
                        placeholder={this.props.placeholder}
                        ref={(e) => {
                            if(e !== null && this.refForm)
                                this.refForm.addInput(e)
                        }}
                        required={this.props.required}
                        onChange={e => {
                            this.handleOnChange(e)
                            if(this.props.onChange)
                                this.props.onChange(e)
                        }}
                        defaultValue={this.props.value}></textarea>
                </div>
                {(this.props.description !== '') ? <span className={"description"}>{this.props.description}</span> : ''}
            </div>
        )
    }
    handleOnChange(e) {
        /** Déclaration des variables */
        let current = e.currentTarget
        if(current) {

            /** Mémorise la valeur en cours */
            this.value = current.value

            /** Identifie si le champs est modifié ou non */
            this.edited = this.defaultValue !== current.value
            current.setAttribute('data-edited', this.edited)
        }

        current.setAttribute('data-valid', this.Valid())

        /** Controle la validité du formulaire */
        if(this.props.refForm.handleFormChange) {
            this.props.refForm.handleFormChange()
        }
        else {
            console.error('La fonction "handleFormChange" n\'est pas définie dans la classe '+this.constructor.name)
        }
    }
    Valid() {
        /** Réinitialise la validité du champ */
        this.valid = true

        /** Test si le champ est obligatoire */
        this.valid = this.validRequired()

        /** Retour de la validité du champ */
        return this.valid
    }
    isEmpty() {
        return ([''].indexOf(this.value.trim()) !== -1)
    }
    validRequired() {
        if(this.props.required) {
            return !this.isEmpty()
        }
        return true
    }
}
MyTextaera.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string
}
MyTextaera.defaultProps = {
    placeholder: '',
    className: ''
}

export default MyTextaera