import {Component} from "react";
import PropTypes from "prop-types";

class MyAlert extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <p className={"text-"+this.props.variant+" msg-alert"}>{this.props.message}</p>
        );
    }
}

MyAlert.propTypes = {
    message: PropTypes.string.isRequired,
    dismissible: PropTypes.bool,
    variant: PropTypes.string.isRequired
}

MyAlert.defaultProps = {
    dismissible: false,
    variant: 'primary'
}

export default MyAlert