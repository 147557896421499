import MyInput from "../../../App/MyInput/MyInput";
import MySelect from "../../../App/MySelect/MySelect";
import MyTextaera from "../../../App/MyTextaera/MyTextaera";
import Heart from "../../../App/Heart/Heart";

class Help extends Heart {
    constructor(props) {
        super(props);

        this.state = {
            alerts: '',
            listeDemandeAssistance: []
        }
    }

    componentDidMount() {
        this.props.App.Utilisateur.DemandeAssistanceLister()
        .then((data) => {
            this.setState({
                listeDemandeAssistance: data.lesObjets.map((line) => {
                    return { key: line.ID, label: line.LIBELLE}
                })
            })
        });
    }

    handleSubmit(e) {
        e.preventDefault()
        let Champ = {
            Cat: document.getElementById("Category"),
            Sub: document.getElementById("Subjet"),
            Mes: document.getElementById("Message")
        }

        let submit = document.getElementById('SendHelp')
        submit.classList.add('disabled')
        submit.setAttribute('disabled', true)

        let optionSelected
        let Options = Champ.Cat.getElementsByTagName('option')
        Array.from(Options).forEach((l) => {
            if(l.selected) optionSelected = l
        })
        this.props.App.Utilisateur.Assistance(optionSelected.innerText, Champ.Sub.value, Champ.Mes.value)
            .then(data => {
                this.setAlert("Votre demande a été envoyée à nos équipes, nous nous efforçons de vous répondre au plus vite.", 'success')
                submit.classList.remove('disabled')
                submit.removeAttribute('disabled')
            }, data => {
                this.setAlert(data.message)
                submit.classList.remove('disabled')
                submit.removeAttribute('disabled')
            });
    }
    handleFormChange() {
        this.handleFormChangeAll()
    }
    render() {
        this.inputs = []
        return (
            <div className="Help">
                <div className="Subtitle">
                    <p>Si vous rencontrez un problème avec votre compte ou avec l’un des sites du groupe l’école des loisirs, vous pouvez nous contacter à tout moment en utilisant le formulaire ci-dessous.<br/>Nos équipes tâcheront de vous répondre dans les plus brefs délais.</p>
                </div>
                <form onSubmit={(e) => this.handleSubmit(e)} className={"row"}>
                    <MySelect
                        refForm={this}
                        label={"Quel est le sujet de votre demande ?"}
                        name={"Category"}
                        className={"col-12"}
                        id={"Category"}
                        placeholder={"Veuillez sélectionner une rubrique"}
                        required={true}
                        data={this.state.listeDemandeAssistance}
                    />
                    <MyInput
                        name={"Subjet"}
                        id={"Subjet"}
                        label={"Objet"}
                        required={true}
                        refForm={this}
                        placeholder={"Écrivez-ici votre message..."}
                    />
                    <MyTextaera
                        name={"Message"}
                        id={"Message"}
                        refForm={this}
                        required={true}
                        label={"Décrivez votre demande"}
                        placeholder={"Écrivez-ici votre message..."}
                        row={"6"}
                    />
                    <div className={"text-center"}>
                        {this.state.alerts}
                        <button className={"btn btn-primary disabled buttonPadding"} disabled={true} type={"submit"} id={"SendHelp"} ref={this.refSubmit}>Envoyer</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default Help