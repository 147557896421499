import MyInput from "../../App/MyInput/MyInput";
import {Regex} from "../../App/Regex";
import React from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import axios from "axios";

export const FormRegister = ({ refSubmit, refForm, handleSubmit, alerts, setAlert }) => {

    const {executeRecaptcha} = useGoogleReCaptcha();

    return <form className={"row"} onSubmit={async (e) => {

        e.preventDefault()

        if(process.env.REACT_APP_CAPTCHA_ACTIF === "true")
        {
            const token = await executeRecaptcha('login')

            /** Vérifiez le score (par exemple, si le score est supérieur à 0.5, considérez-le comme valide) */
            if (token) {
                axios.post("/google/token/recaptcha", {
                    token: token
                }, {
                    baseURL: process.env.REACT_APP_DOMAIN_BACK
                }).then((Res) => {
                    const {success, score} = Res.data;
                    console.log(score)
                    if (success && score > 0.5) {
                        handleSubmit(e)
                    } else {
                        setAlert('Échec de la validation reCAPTCHA.', 'danger', true)
                    }
                })
            }
        } else {
            handleSubmit(e)
        }
    }}>
        <MyInput
            label="Nom"
            refForm={refForm}
            placeholder="DUPONT"
            autocomplete="family-name"
            className={"col-6"}
            id="name"
            name="name"
            type="text"
            required={true}/>
        <MyInput
            label="Prénom"
            refForm={refForm}
            placeholder="Louise"
            autocomplete="given-name"
            className={"col-6"}
            id="firstName"
            name="firstName"
            type="text"
            required={true}/>
        <MyInput
            label="Adresse mail"
            refForm={refForm}
            placeholder="adresse@mail.com"
            autocomplete="email"
            className={"col-12"}
            id="mail"
            name="mail"
            type="text"
            required={true}
            regex={Regex.mail}
            messageRegexError={'Veuillez utiliser une adresse mail valide'}
        />
        <MyInput
            label={"Mot de passe"}
            refForm={refForm}
            type={"password"}
            name={"password"}
            className={"col-12"}
            id={"password"}
            placeholder={"••••••••••••"}
            autoComplete={"new-password"}
            description={<p>Veuillez utiliser une combinaison de 8 caractères, incluant des <strong>minuscules,
                majuscules, chiffres et caractères spéciaux (+=%#/*?!,.)</strong></p>}
            required={true}
            regex={Regex.password}
            messageRegexError={'Veuillez utiliser un mot de passe valide'}
        />
        <p className={"text-center condition"}>
            <span>En cliquant sur “Créer mon compte”, vous accepter les </span>
            <a href="/conditions/utilisation" className={"text-primary"}>conditions générales d’utilisation</a>
            <span> et la </span>
            <a href="/politique/confidentiel" className={"text-primary"}>politique de confidentialité</a>.</p>
        {alerts ?? ''}
        <button type={"submit"} className="btn btn-primary disabled" disabled={true} ref={refSubmit}
                id={"RegisterAccount"}>Créer mon compte
        </button>
    </form>
}