import './MySwitch.css'
import Heart from "../Heart/Heart";
import React from 'react'


class MySwitch extends Heart {
    constructor(props) {
        super(props);
        this.state = { checked: !!this.props.checked ?? false }
        this.defaultValue = !!props.checked
    }
    render() {
        let label = <label className={(this.state.checked === true) ? 'form-check-label text-primary' : 'form-check-label text-primary d-none'} htmlFor={this.props.id}>Abonné(e)</label>;
        return (
            <div className="MySwitch form-check form-switch">
                {label}
                <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    data-id={this.props.dataId}
                    id={this.props.id}
                    onChange={(e) => {
                        this.handleChange(e)
                        let current = e.currentTarget
                        if(current) {
                            current.setAttribute('data-edited', this.defaultValue !== current.checked);
                        }
                    }}
                    checked={this.state.checked} />
            </div>
        )
    }
    handleChange = (event) => {
        let target = event.target;
        this.setState({ checked: target.checked });
    }
}
MySwitch.propTypes = {

}
MySwitch.defaultProps = {
    checked: false
}

export default MySwitch;