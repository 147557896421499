import './UpdatePassword.css'
import Heart from "../../../App/Heart/Heart";
import MyInput from "../../../App/MyInput/MyInput";
import Information from "../../../App/Information/Information";
import {Regex} from "../../../App/Regex";

class UpdatePassword extends Heart {
    description = <p>Veuillez utiliser une combinaison de 8 caractères, incluant des <strong>minuscules, majuscules, chiffres et caractères spéciaux (+=%#/*?!,.)</strong></p>
    constructor(props) {
        super(props);

        this.state = {
            refresh: '',
            description: '',
            passwordElementsRequired: {}
        }
    }

    componentDidMount() {
        this.props.App.Utilisateur.PasswordRegex()
            .then((data) => {
                this.setState({passwordElementsRequired: data})
            });
    }

    handleSubmit(e) {
        e.preventDefault()
        let Champs = {
            Current: document.getElementById('current-password'),
            New: document.getElementById('new-password'),
            Conf: document.getElementById('conf-password')
        }

        if(Champs.New.value === Champs.Conf.value) {
            this.refSubmit.current.classList.add('disabled')
            this.refSubmit.current.setAttribute('disabled', true)
            this.props.App.Utilisateur.UpdatePassword(Champs.Current.value, Champs.New.value)
                .then((data) => this.setAlert(data.message, 'success'),
                    (data) => this.setAlert(data.message))
        }
    }
    handleFormChange() {
        this.handleFormChangeAll()
    }
    resetForm() {
        this.inputs.forEach(input => {
            input.value = ''
            input.removeAttribute('data-edited')
            input.removeAttribute('data-valid')
        })
    }
    render() {
        this.inputs = []
        let passwordElementsRequired = '';
        if(this.state.passwordElementsRequired.hasOwnProperty("tLegende"))
        {
            this.state.passwordElementsRequired.tLegende.forEach((ligne, key) => {
                if (key === this.state.passwordElementsRequired.tLegende.length - 1) {
                    passwordElementsRequired += ` et ${ligne}`;
                } else {
                    passwordElementsRequired += ligne + `${key === this.state.passwordElementsRequired.tLegende.length - 2 ? '' : ', '}`;
                }
            })
            this.description = (<><p>Veuillez utiliser une combinaison de {this.state.passwordElementsRequired.nLongueurMin} caractères, et y incluant au minimum : <strong>{passwordElementsRequired}</strong></p></>);
        }
        return (
            <div className={"UpdatePassword"}>
                <div className="Subtitle"><h3>Modifier mon mot de passe</h3></div>
                <div className="Body">
                    <form
                        onSubmit={(e) => this.handleSubmit(e) }
                        ref={this.refForm}>
                        <div className={"zeorihgj"}>
                            <div className={"zrijfedkos row"}>
                                <MyInput
                                    label={"votre Mot de passe actuel"}
                                    type={"password"}
                                    refForm={this}
                                    name={"current-password"}
                                    className={"col-12"}
                                    id={"current-password"}
                                    placeholder={"••••••••••••"}
                                    autoComplete={"current-password"}
                                    regex={Regex.password}
                                />
                                <MyInput
                                    label={"votre nouveau Mot de passe"}
                                    type={"password"}
                                    refForm={this}
                                    name={"new-password"}
                                    className={"col-12"}
                                    id={"new-password"}
                                    placeholder={"••••••••••••"}
                                    autoComplete={"new-password"}
                                    regex={Regex.password} />
                                <MyInput
                                    label={"Confirmez votre nouveau mot de passe"}
                                    type={"password"}
                                    refForm={this}
                                    name={"conf-password"}
                                    className={"col-12"}
                                    id={"conf-password"}
                                    placeholder={"••••••••••••"}
                                    autoComplete={"new-password"}
                                    description={this.description}
                                    regex={Regex.password} />
                            </div>
                            <Information title={"Sécurisez votre compte"} description={"Pour renforcer la sécurité de votre compte, nous vous recommandons d’utilisez un mot de passe unique dédié à votre compte l’école des loisirs et d’en changer régulièrement."}/>

                        </div>
                        <div className={"aldfjvhkc"}>
                            <div>
                                {this.state.alerts}
                                <button className={"btn btn-primary disabled buttonPadding"} disabled={true} ref={this.refSubmit}>Enregistrer les modifications</button>
                            </div>
                            <div>
                                <a href="/utilisateur/oublier" onClick={(e) => e.preventDefault()} className={"text-dark"}>J’ai oublié mon mot de passe</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default UpdatePassword