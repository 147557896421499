
import './Home.css'
import MyCarousel from "./MyCarousel/MyCarousel";
import logo from "../../logo.svg";
import {Component} from "react";
import Connection from "./Connection/Connection";
import ForgetPassword from "./ForgetPassword/ForgetPassword";

class Home extends Component {
    constructor(props) {
        super(props);
        console.log(this.props);

        this.state = {
            currentComponent: <Connection ref={(current) => this.currentComponent = current} Accueil={this} App={this.props.App} />,
            componentForgetPassword: (this.props.hasOwnProperty("component") ? <ForgetPassword Accueil={this} App={this.props.App} /> : null)
        };
        console.log(this.state);

        this.switchContainer = this.switchContainer.bind(this);
    }

    componentDidMount() {
        if(this.props.hasOwnProperty("component") && this.state.componentForgetPassword !== null)
        {
            console.log(this.state.componentForgetPassword)
            this.switchContainer(this.state.componentForgetPassword);
        }
    }

    switchContainer(nextComponent) {
        this.setState({
            currentComponent: nextComponent
        })
    }
    render () {
        return (
            <div className="Accueil">
                <div className="Container">
                    <img src={logo} className="Logo" alt="logo de l'école des loisirs"/>
                    <h1 className="Titre">Mon compte<br></br>l'école des loisirs<span className="text-primary">.</span></h1>
                    <h3 className="SousTitre">Un espace unique dédié à la gestion de vos informations, newsletters et échanges au sein des sites du groupe l’école des loisirs.</h3>
                    <div id="AccueilForm">{this.state.currentComponent}</div>
                </div>
                <MyCarousel />
            </div>
        )
    }
}

export default Home