
import './Connection.css';
import Register from "../Register/Register";
import Heart from "../../App/Heart/Heart";
import ForgetPassword from "../ForgetPassword/ForgetPassword";
import React from "react";
import {FormConnection} from "./FormConnection";

class Connection extends Heart {
    constructor(props) {
        super(props);

        this.state = {
            alerts: ''
        }
    }
    render() {
        this.inputs = []
        window.localStorage.removeItem("idMenu");
        return (
            <div className="Connexion">
                <FormConnection
                    refSubmit={this.refSubmit}
                    setAlert={(message, variant, dismissible) => this.setAlert(message, variant, dismissible)}
                    handleSubmit={(e) => this.handleSubmit(e)}
                    refForm={this}
                    alerts={this.state.alerts}
                />
                <p className={"text-center"}>
                    <a
                        href={"/utilisateur/oublier"}
                        className={"text-primary"}
                        onClick={(event) => {
                            event.preventDefault()
                            /**
                             * TODO - METHODE A SUPPRIMER | VOIR POUR METTRE EN PLACE LE ROUTEUR SUR L'ENSEMBLE DU SITE
                             * **/
                            window.history.replaceState(null, '', window.location.origin+'/motdepasse/oublier');
                            this.handleNextComponent(<ForgetPassword Accueil={this.props.Accueil} App={this.props.App} />)
                        }}
                    >j'ai oublié mon mot de passe</a>
                </p>
                <p className="sign-up">
                    <span>Vous n'avez pas encore de compte ? </span>
                    <a
                        href={"/utilisateur/inscription"}
                        className={"text-primary"}
                        onClick={(e) => {
                            e.preventDefault()
                            this.handleNextComponent(<Register Accueil={this.props.Accueil} App={this.props.App} />)
                        }}
                    >Créer un compte</a>
                </p>
            </div>
        );
    }
    handleNextComponent(component) {
        this.props.Accueil.switchContainer(component)
    }
    handleSubmit(event) {
        /** Annule l'évènement navigateur */
        event.preventDefault();

        /** Déclaration des variables */
        let Champ = { Mail: document.getElementById("mail"), Password: document.getElementById("password") }
        let submit = document.getElementById('ConnectionAccount')

        /** Récupération des valeurs du formulaires */
        Champ = { Mail: Champ.Mail.value, Password: Champ.Password.value }

        /** Bloque le bouton de connexion */
        submit.classList.add('disabled')
        submit.setAttribute('disabled', true)

        /** Affichage de l'alerte */
        this.setAlert('Connexion en cours...', 'dark', false)

        /** Lancement de la connexion */
        this.props.App.Utilisateur.Connexion(Champ.Mail, Champ.Password)
            .then(() => {

                /** Chargement des données de l'utilisateur */
                this.props.App.Utilisateur.Charger()
                    .then((data) => {
                        if (data.resultat) {
                            this.props.App.setLogging(this.props.App.isLogging())
                            this.props.App.loopLogging()
                        }
                    })
        }, data => {
            /** Connexion échouée */
            this.setAlert(data.message)

            /** Bloque le bouton de connexion */
            submit.classList.remove('disabled')
            submit.removeAttribute('disabled')

        })
    }
    handleFormChange() {
        this.handleFormChangeAll()
    }
}

export default Connection;